@import "variables";
@import "~@coreui/coreui-pro/scss/bootstrap/mixins/_breakpoints.scss";

// Here you can add other styles
a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

body {
  background-color: $gray-10;
  font-family: $Roboto;
  color: $blue-80;
}

.font-48 {
  font-size: 48px;

  @include media-breakpoint-down(md) {
    font-size: 36px;
  }
}

.font-32 {
  font-size: 32px;

  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}

.font-28 {
  font-size: 28px;

  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}

.font-24 {
  font-size: 24px;

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
}

.font-20 {
  font-size: 20px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.font-18 {
  font-size: 18px;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

.font-17 {
  font-size: 17px;

  @include media-breakpoint-down(md) {
    font-size: 13px;
  }
}

.font-16 {
  font-size: 16px;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.font-14 {
  font-size: 14px;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.font-12 {
  font-size: 12px;

  @include media-breakpoint-down(md) {
    font-size: 10px;
  }
}

@include media-breakpoint-up(xs) {
  .modal-dialog {
    max-width: 544px;
  }
}

.box-shadow {
  box-shadow: $shadow-light;
}

.selectable {
  cursor: pointer;

  input,
  label {
    cursor: pointer;
  }
}

.form-check-input + .form-check-label {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  line-height: 1.8rem
}

.alert {
  &:first-letter {
    text-transform: uppercase;
  }
}

input[type='radio'] {
  &.status-check {
    appearance: none;
    width: 12px;
    height: 12px;

    & + label {
      color: $gray-30;
    }

    &:checked + label {
      color: $blue-80;
    }

    &:after {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $gray;
      content: '';
      display: inline-block;
      visibility: visible;
    }

    &:checked:after {
      background-color: $green-10;
    }

    &.is-invalid {
      &:after {
        background-color: rgba($error, 0.8);
      }

      & + label {
        color: $error;
      }
    }
  }

  &.active-radio {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $gray-30;
    outline: none;
    margin-right: 12px;
    position: static;
    margin-top: 0;
    margin-left: 0;

    &:checked {
      border: 6px solid $dark-blue;
    }
  }
}

.Toastify {
  .Toastify__toast-container--bottom-center {
    margin: 0;
    transform: translateZ(0) scale(1.0, 1.0) translate(-50%, 0);
  }

  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    @extend .Toastify__toast-container--bottom-center;
  }

  .Toastify__toast-container {
    width: auto;
    max-width: 100%;
    left: 50%;
  }

  .Tastily__toast--custom {
    border-radius: 6px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    width: auto;
    clear: both;
    min-height: 48px;
    min-width: 368px;
  }

  .Toastify__close-button {
    &--success, &--error {
      display: none;
    }
  }

  .Toastify__toast--error {
    @extend .Tastily__toast--custom;
    background-color: $rose-red;
  }

  .Toastify__toast--success {
    @extend .Tastily__toast--custom;
    background-color: $green-10;
  }
}

.subtext {
  color: $gray-50;
}

.max-width-330 {
  max-width: 330px;
}

.max-width-264 {
  max-width: 264px;
}

.max-width-180 {
  max-width: 180px;
}

.icon-info {
  cursor: pointer;
}

.dropdown-toggle {
  &:after {
    display: none;
  }

  i {
    transition: transform 0.1s;
  }

  &[aria-expanded="true"] {
    i {
      transform: rotate(180deg);
    }
  }
}

.btn:focus, .btn.focus {
  box-shadow: none;
}

.font-weight-semi {
  font-weight: 500;
}

.rotate-left-90 {
  transform: rotate(-90deg);
}

.rotate-right-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.icon-halo {
  width: 48px;
  height: 48px;
  background: $blue-20;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
