// cssnano produces improper output in postcss-normalize-positions
// clean-css
// this is a temp fix to avoid the issue where
// input:
// background-position: center right $input-height-inner-quarter;
// produces output:
// background-position: 100% $input-height-inner-quarter;

@import "variables";

.was-validated .form-control:valid, .form-control.is-valid {
  background-position: right $input-height-inner-quarter center;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-position: right $input-height-inner-quarter center;
}

button:focus, input:focus, textarea:focus, select:focus{
  outline: none;
}

body {
  select.pb-4,
  select.py-4 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 3rem;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    line-height: 0;
  }

  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    margin: 0;
  }
}

.form-control {
  &.is-invalid, &.is-valid {
    background-image: unset;
  }
}

input.form-control {
  &[type="text"], &[type="password"] {
    min-height: 52px;
  }
}

select.form-control {
  height: 52px;

  option[value=""][disabled] {
    display: none;
  }
}

.Select-control {
  min-height: 52px;

  .Select-value, .Select-placeholder, .Select-input {
    line-height: 52px !important;
    padding: 0 16px !important;
    height: 52px;
  }
  .Select-input>input {
    line-height: 52px !important;
    width: 100% !important;
    padding: 0;
  }
}

.btn-outline-info {
  color: $red-30 !important;
  background-color: $white;
  border-color: $red-30;

  &:hover {
    color: rgba($red-30, 0.8) !important;
    background-color: $white;
    border-color: rgba($red-30, 0.8);
  }
}

.btn-outline-red {
  color: $red-30;
  &:active,
  &:hover{
    color: $white;
    background-color: $red-30;
  }
}

.btn-lg {
  padding: 0.8rem 1rem 0.825rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-link {
  font-weight: 500;
  color: $red-30;

  &:hover, &:focus {
    text-decoration: none;
    color: rgba($red-30, 0.8);
  }
}

.btn-ghost-danger {
  color: $error;

  &:not(.disabled), &:not(disabled) {
    &:hover, &:focus, &:active {
      background: $white !important;
      color: rgba($error, 0.8) !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.ps {
  .ps__rail-y, .ps__rail-x, .ps__rail-y:hover, .ps__rail-x:hover {
    .ps__thumb-y, .ps__thumb-x {
      background-color: $red-30;
    }
  }
}
